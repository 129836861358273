import { NextPage } from 'next'
import { useRouter } from 'next/router'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import { PrimaryButton } from 'styles/defaultStyles'
import { media } from 'utilities/helpers'
import notFound from '../public/assets/svg/notFound/404notFound.json'
const NotFound: NextPage = () => {
  const router = useRouter()
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notFound,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div>
      <Lottie options={defaultOptions} width={300} height={300} />
      <NotFoundPageContent>
        {`We've explored deep and wide, but we can't find the page you were looking for.`}
      </NotFoundPageContent>
      <NavigateButtonWrapper>
        <PrimaryButton onClick={() => router.push('/')} width={213}>
          Navigate back home
        </PrimaryButton>
      </NavigateButtonWrapper>{' '}
    </div>
  )
}
export default NotFound
export const NavigateButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0px;
`
export const NotFoundPageContent = styled.div`
  font-weight: 400;
  font-size: 28px;
  padding: 0px 10px;
  color: ${({ theme }) => theme.text};
  text-align: center;
  margin-top: 50px;
  ${media.md`
  font-size: 24px; 
  `}
  ${media.sm`
  font-size: 18px;
  `}
  ${media.s`
  font-size: 16px;
  `}
`
